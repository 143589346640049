<!--<div class="modal-header">-->

<!--  <div class="share-icon">-->
<!--    <p><span class="share-icon"><i class="fa fa-share-alt mr-2" aria-hidden="true"></i></span>Share</p>-->
<!--  </div>-->
<!--  &lt;!&ndash;    <div class="d-flex align-items-start pl-5">&ndash;&gt;-->
<!--  &lt;!&ndash;      <p class="share-text mr-2 pb-0">{{enableSharingToggleText}}</p>&ndash;&gt;-->
<!--  &lt;!&ndash;      <div class="custom-control custom-switch d-flex align-items-start">&ndash;&gt;-->
<!--  &lt;!&ndash;        <input type="checkbox" [(ngModel)]="checked" class="custom-control-input toggler" id="customSwitches" (change)="toggleSharing($event)">&ndash;&gt;-->
<!--  &lt;!&ndash;        <label class="custom-control-label" for="customSwitches"></label>&ndash;&gt;-->
<!--  &lt;!&ndash;      </div>&ndash;&gt;-->
<!--  &lt;!&ndash;    </div>&ndash;&gt;-->
<!--</div>-->

<div class="w-auto">
  <div class="d-flex align-items-center justify-content-between px-3 pt-3 w-100 border-bottom-1">
    <div class="share-icon">
      <p><span class="share-icon"><i class="fa fa-share-alt mr-2" aria-hidden="true"></i></span>Share</p>
    </div>
    <div class="d-flex justify-content-end">
      <button type="button" aria-label="Close button" class="close mb-2" aria-describedby="modal-title"
              (click)="modal.dismiss('cancel')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
<!--    <div class="d-flex align-items-start pl-5">-->
<!--      <p class="share-text mr-2 pb-0">{{enableSharingToggleText}}</p>-->
<!--      <div class="custom-control custom-switch d-flex align-items-start">-->
<!--        <input type="checkbox" [(ngModel)]="checked" class="custom-control-input toggler" id="customSwitches" (change)="toggleSharing($event)">-->
<!--        <label class="custom-control-label" for="customSwitches"></label>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="px-4 py-5">
    <div *ngIf="isShareEnabled"
    >
      <div class="pb-3" [ngClass]="{'border-bottom-1': isCopyLink || showSendEmail || isDownload}">
        <p class="display-text" *ngIf="isDownload">Click to enable sharing report with the others or download the report.</p>
        <p class="display-text" *ngIf="!isDownload">Click to enable sharing the report with others, or email the report at regular intervals.</p>
        <div class="w-auto text-center">
          <button type="button" class="btn btn-default border-radius-8 mr-2" (click)="copyLink()"><span class="mr-2"><i class="fa fa-link" aria-hidden="true"></i></span>Copy Link</button>
          <button type="button" class="btn btn-default border-radius-8 mr-2" (click)="emailReportUpdate()"><span class="mr-2"><i class="fa fa-envelope" aria-hidden="true"></i></span>Email Report Update</button>
          <button [hidden]="true" type="button" class="btn btn-default border-radius-8" (click)="download()"><span class="mr-2"><i class="fa fa-download" aria-hidden="true"></i></span>Download PDF</button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isDownload && !isCopyLink && isShareEnabled && !showSendEmail"
    >
      <div class="py-5 px-4">
        <button type="button" class="btn btn-default border-radius-8 mr-2" (click)="startDownload()"><span class="mr-2"><i class="fa fa-arrow-circle-down" aria-hidden="true"></i></span>Download</button>
      </div>
    </ng-container>
    <ng-container *ngIf="isCopyLink && isShareEnabled && !showSendEmail && !isDownload"
    >
      <div class="mt-5 px-4">
        <p class="display-text text-left">Copy the link below and send it to other. Anyone with the following link have access to the report</p>
        <div class="w-100 text-left">
          <div class="d-flex align-items-center flex-wrap">
            <div class="input no-box-shadow w-auto text-left d-flex align-items-center mr-2 px-2">{{link}}</div>
            <button type="button" class="btn btn-default border-radius-8 w-auto" (click)="copyLinkToClipboard($event)"><span class="mr-2"><i class="fa fa-copy" aria-hidden="true"></i></span>Copy Link</button>
          </div>
          <button [hidden]="true" type="button" class="btn btn-default border-radius-8 mt-2" (click)="disableLink()">Disable Link</button>
        </div>
        <div class="mt-4">
          <form (ngSubmit)="setShareReportPassword()" [formGroup]="passwordForm" class="d-flex align-items-center">
            <div class="floating-label">
              <input [ngClass]="{ 'is-invalid': getPasswordControl.password.errors }" class="floating-input" formControlName="password" placeholder=" "
                      required="required"/>
              <span class="highlight"></span>
              <label>Password <span class="red">*</span></label>

              <div *ngIf="submitted && getPasswordControl.password.errors" class="bar error-brd">
                  <div *ngIf="getPasswordControl.password.errors.required" class="invalid-text">Password is required</div>
              </div>
            </div>
            <div class="text-center pl-md-2">
              <button type="submit" class="btn btn-default border-radius-8 mr-2">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showSendEmail && isShareEnabled && !isCopyLink && !isDownload"
    >
      <p class="display-text" style='margin-top: 15px;margin-bottom: 0px'>The report will only be sent for as long as the project is active (i.e. inactive as of end date specified).</p>
    <form [formGroup]="shareReportsForm" class="px-4 py-5 w-100">
      <div class="w-100">
        <div class="d-flex justify-content-start">
          <p class="title-text">Send Email</p>
        </div>
        <div class="d-flex align-items-center">
          <div class="client-dropdown mr-1 w-100">
            <app-creatable-select
              [isDisabled]="isNotificationSet"
              [selectedValue]="timing" [labelKey]="'name'" [valueKey]="'value'"
              [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"
              [placeholder]="'Select Timing *'" [selectOptions]="reportTimingOptions"
              (optionSelected)="timingSelected($event)"></app-creatable-select>
          </div>
          <div class="col-xs-1 text-center">on</div>
          <div class="client-dropdown w-100 ml-1">
            <app-creatable-select
              [selectedValue]="selectedSchedule" [labelKey]="'name'" [valueKey]="'name'"
              [isDisabled]="isDisabled || isNotificationSet"
              [hideLabel]="true" [onlyBottomBorder]="false" [isCreatable]="false"
              [placeholder]="'Select Schedule *'" [selectOptions]="scheduleOptions"
              (optionSelected)="scheduleSelected($event)"></app-creatable-select>
          </div>
        </div>
        <div class="d-flex justify-content-start w-100" *ngIf="isDailySelected">
          <div class="floating-label w-100">
            <app-timezone-picker class="dropdown-panel-timezone" [setDefaultValue]="shareReportsForm.get('timezone').value" (formControlValue)="getTimezoneValue($event)" [isDisabled]="isNotificationSet" [customPlaceholderText]="'Time Zone'" [isClearable]='true'></app-timezone-picker>
<!--            <ng-moment-timezone-picker class="dropdown-panel-timezone" [disabled]="isNotificationSet" [formControlName]="'timezone'" [customPlaceholderText]='"Time Zone"' [clearable]='true'></ng-moment-timezone-picker>-->
          </div>
        </div>
      </div>

      <div class="mt-4 mx-0 w-100">
        <p class="title-text">Recipients</p>
        <div
        class="w-100"
        formArrayName="recipients"
        *ngFor="let recipient of shareReportsForm.get('recipients')['controls']; let i = index;"
        >
          <div [formGroupName]="i" class="mt-2 w-100 position-relative">
              <input [readOnly]="isNotificationSet" type="email" class="px-2 w-100 input" formControlName="email" placeholder="Email Address" autoComplete="none">
              <div class="floating-element position-absolute h-100 cursor-pointer" (click)="!isNotificationSet && removeRecipient(i)"></div>
          </div>
        </div>
        <button [hidden]="isNotificationSet" class="input add-recipient no-box-shadow mt-2 pl-2 w-100 text-left" type="button" (click)="addRecipient()">Add Another Email</button>
      </div>
      <div class="w-auto text-left pt-3">
        <button type="button" class="btn btn-default border-radius-8 mr-2 h-34" (click)="stopNotification()" [disabled]="!isNotificationSet">Stop Notifications</button>
        <button type="button" class="btn btn-default border-radius-8" (click)="setNotification()" [disabled]="isNotificationSet">Set Report Notifications</button>
      </div>
    </form>
    </ng-container>
  </div>
</div>
