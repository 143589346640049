import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PackagesRoutingModule } from './packages-routing.module';
import { PackageListComponent } from './package-list/package-list.component';
import {DataTablesModule} from 'angular-datatables';
import { PackageAddComponent } from './package-add/package-add.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import { MatMenuModule } from '@angular/material/menu';
import { PopoverModule } from 'ngx-bootstrap/popover';

@NgModule({
  declarations: [
    PackageListComponent,
    PackageAddComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    PackagesRoutingModule,
    NgbPopoverModule,
    MatMenuModule,
    PopoverModule.forRoot(),
  ]
})
export class PackagesModule { }
