import { Component, OnInit, OnDestroy } from '@angular/core';
import {Client, Package} from '@app/modules/shared/models';
import {
  getPackage, getPackageError,
  getPackageSuccess,
  IPackageState, PackagesAdd, PackagesGet, PackagesUpdate,
  ResetPackageState
} from '@app/stores';
import {Constants} from '@app/consts';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-package-add',
  templateUrl: './package-add.component.html',
  styleUrls: ['./package-add.component.css'],
  providers: [Constants]
})
export class PackageAddComponent implements OnInit, OnDestroy {

  addPackageForm: UntypedFormGroup;
  unsubscriber = new Subject();
  public submitted = false;
  public packageId: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private packageStore: Store<IPackageState>,
    private toastrService: ToastrService,
    public constants: Constants,
  ) {
    this.packageStore.dispatch(ResetPackageState({params: {}}));
    this.subscribeStores();
  }

  subscribeStores() {
    this.packageStore.pipe(select(getPackageError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.packageStore.pipe(select(getPackageSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            this.submitted = false;
            this.cancelClick();
          }
        }
      });

    this.packageStore.pipe(select(getPackage))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(pkg => {
        if (pkg) {
          this.addPackageForm.patchValue({...pkg});
        }
      });
  }


  ngOnInit(): void {
    this.addPackageForm = this.formBuilder.group({
      name: ['', Validators.required],
      project_count_limit: [null],
      project_campaign_count_limit: [null],
      project_influencer_count_limit: [null],
    });
    this.route.paramMap.subscribe(params => {
      if (params.has('id')) {
        this.packageId = +params.get('id');
        this.loadPackage(this.packageId);
      }
    });
  }

  get f() {
    return this.addPackageForm.controls;
  }

  loadPackage(packageId: number) {
    this.packageStore.dispatch(PackagesGet({packageId}));
  }

  savePackage() {
    this.submitted = true;
    if (this.addPackageForm.controls.name.status === 'INVALID') {
      return;
    }
    if (this.packageId) {
      this.packageStore.dispatch(PackagesUpdate({packageId: this.packageId, body: this.addPackageForm.value}));
    } else {
      this.packageStore.dispatch(PackagesAdd({body: this.addPackageForm.value}));
    }
  }

  cancelClick() {
    this.router.navigate(['settings/packages']);
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }

}
