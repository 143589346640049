import {Component, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  getTrackingType, getTrackingTypeError,
  ITrackingTypeState, ResetTrackingTypeState, TrackingType, UpdateTrackingTypeStatus
} from '@app/stores';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from "angular-datatables";
import moment from "moment-timezone";

@Component({
  selector: 'app-tracking-accuracy-modal',
  templateUrl: './tracking-accuracy-modal.component.html',
  styleUrls: ['./tracking-accuracy-modal.component.scss'],
  providers: []
})
export class TrackingAccuracyModalComponent implements OnInit, OnDestroy {
  @Input() projectId;
  unsubscriber = new Subject();
  trackingType = [];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any;
  _moment = moment;
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  private ajaxCallback: any;
  private datatableInstance: any;

  constructor(
    private toastr: ToastrService,
    private trackingTypeStore: Store<ITrackingTypeState>,
    public modal: NgbActiveModal,
  ) {
    this.trackingTypeStore.dispatch(ResetTrackingTypeState({params: {error: '', success: ''}}));
  }

  ngOnInit(): void {
    this.trackingTypeStore.dispatch(TrackingType({projectId: this.projectId}));
    this.subscribeStore();
  }

  subscribeStore(): void {
    this.trackingTypeStore.pipe(select(getTrackingTypeError))
    .pipe(takeUntil(this.unsubscriber))
    .subscribe(error => {
      if (error) {
        this.toastr.error(error, 'Error');
      }
    });

    this.trackingTypeStore.pipe(select(getTrackingType))
    .pipe(takeUntil(this.unsubscriber))
    .subscribe((trackingType: any) => {
      if (trackingType) {
        const sortedTrackingType = [...trackingType];
        this.trackingType = sortedTrackingType.sort((a, b) => b.project_total - a.project_total);
      }
    });
  }

  datatableSettings() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      processing: true,
      serverSide: true,
      ordering: true,
      columnDefs: [{orderable: false, targets: 'no-sort'}],
      pageLength: 10,
      paging: true,
      scrollX: true,
      searching: true,
      lengthChange: false,
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ],
      scrollY: '46vh',
      scrollCollapse: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
        });
      },
    };
  }

  ngOnDestroy() {}
  
  saveTrackingAccurancy() {
    this.trackingTypeStore.dispatch(UpdateTrackingTypeStatus({ projectId: this.projectId, trackingType: this.trackingType }))
    this.modal.close(true)
  }

}
