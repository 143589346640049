import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ShareReportModalComponent } from '@app/modules/statistics/share-report-modal/share-report-modal.component';
import {Campaign, InfluencerType, ReportInfluencers, ReportModel, ReportTotals} from '@app/modules/shared/models';
import {select, Store} from '@ngrx/store';
import {
  CampaignGetAll, getCampaigns,
  getInfluencerError, getInfluencersProjectSegment,
  getInfluencersTypes, getLoggedInUser, getProjects,
  getReport,
  getReportError, getReportPassword, IAuthenticationState, ICampaignState,
  IInfluencersState, InfluencersExport, InfluencersProjectSegment,
  InfluencersTypeList, IProjectState,
  IReportState, Report, ReportPassword, ResetReportState, ProjectsReportExport, UploadToSignedUrl,
  IFileUploadState,
  getFileUploadProgress, getUploadToSignedUrl, ReportAdvocatesExport
} from '@app/stores';
import {Subject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {DataTableDirective} from 'angular-datatables';
import moment from 'moment-timezone';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TzDatePipe } from '@app/modules/shared/pipes/tz-date.pipe';
import {AwsService} from '@app/services/aws.service';
import {SocketService} from '@app/services/socket.service';
import * as DataTables from 'datatables.net';
import { Config } from 'datatables.net';
import {TrackingAccuracyModalComponent} from "@app/modules/statistics/tracking-accuracy-modal/tracking-accuracy-modal.component";

declare var vis: any;
declare var $: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;
  @ViewChild('loadReport', { static: true }) modalLoadReport: ElementRef;
  public report: ReportModel = null;
  public reportTotals: ReportTotals = null;
  newDummyCampaign = new Campaign();
  campaigns: Campaign[] = [];
  public influencers: ReportInfluencers = null;
  public pageViews: any = null;
  public conversions: any = null;
  public projectId: number;
  public referrals: any;
  public socketSubscription: any;
  public exportJobId: number;
  dtOptions: any = {};
  series: any = [];
  dates: any = [];
  referralBy = '';
  influencerTypes: InfluencerType[] = [];
  segments: any = [];
  private unsubscriber = new Subject();
  influencersList: any[] = [];
  _object = Object;
  socialShareString: any = ''
  public typeColor: any = [
    '#ee6baa', '#c129d3', '#f0c20a', '#4ce0ec',
    '#662ff1', '#8fe7c6', '#eb2764', '#c11408',
    '#5b9f42', '#4f1573', '#851e42', '#0de3ca',
    '#c4c0e8', '#099a91', '#1c34a8', '#2e150c'];
  private ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private firstTime = true;
  private networkGraph;
  private scaleFactor = 0.1;
  private searchDelay;
  public influencerTypeId: any = null;
  public selectedSegment: any = null;
  public startDate = '';
  public endDate = '';
  public justSetDate = true;
  public showAllColumns = false;
  public tableColumns = [];
  public projects = [];
  public selectedProject = null;
  public selectedChk: any = {all: false};
  public selectedInfluencerTypes: any = {all: false};
  public referralIds = null;
  setStartDate: any;
  setEndDate: any;
  refreshData = null;
  dtTrigger: Subject<any> = new Subject();
  campaignId = null;
  mode = 'live';
  modes = [
    {
      name: 'Live Data',
      id: 'live'
    },
    {
      name: 'Test Data',
      id: 'test'
    }
  ];
  user;
  isAdmin = false;
  hideDiv = {
    socialShare: false,
    emailEngagement: false,
    landingPageViews: false,
    conversions: false,
    summary: false
  };
  selectedSocialNetwork: null;
  @ViewChild('passwordForm', {static: true}) passwordForm: TemplateRef<any>;
  reportPassword = '';
  reportPasswordError = '';
  temporaryToken = '';
  reportPasswordClick = false;
  reportModel: any;
  conversionsGraph = {
    dataLabels: {},
    xaxis: {},
    yaxis: {},
    tooltip: {},
    series: [],
  };
  piechart: any = {
    labels: [],
    series: [],
    colors: [],
    chart: {
      type: 'pie',
      height: '450',
      events: {}
    }
  };
  radialBar: any = {
    series: [],
    colors: [],
    labels: [],
    legend: {},
    stroke: {},
    plotOptions: {},
    responsive: []
  };
  socialNetworkColors: any = {
    facebook: '#316ff6',
    linkedin: '#333399',
    twitter: '#00DBF7',
    sms: '#f14336',
    mail: '#17C7D2',
    whatsapp: '#9ADEA9',
    slack: '#B440CE',
    google: '#ea4335',
    instagram: '#dd2a7b'
  };
  influencerTypesById: any = {};
  groupTypeColor: any = {
    attendees: '#4ab8e3',
    speakers: 'rgb(254, 176, 25)',
    partners: 'rgb(0, 227, 150)',
    campaigns: 'rgb(26,255,0)',
  };
  selectedFromList: any = null;
  _moment = moment;
  chkTimeout = null;
  forceGroup = false;
  isTableHide = false;
  influencerFrontList: any[] = [];
  currentProject: any;
  projectStartDate: any;
  projectEndDate: any;
  loadReport: any;
  isReportLoading = true;
  loadModalReportRef: any;
  isStartDateNull = false;
  isEndDateNull = false;
  csvDownloadComplete = false;
  exportModelRef: any;
  modelOpen = false;
  csvUrl: any;
  uniqueImportString: any;
  exportProcess = false;
  public exportProgress: any = {
    status: 'started',
    progress: 0
  };
  fileName: any;
  fileResponse: any[] = [];

  blobObject: any;

  @ViewChild('csvDownload', {static: true}) csvDownload: TemplateRef<any>;
  public loaderImport = new BehaviorSubject<boolean>(false);
  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private influencerStore: Store<IInfluencersState>,
    private reportStore: Store<IReportState>,
    private campaignStore: Store<ICampaignState>,
    private authenticationStore: Store<IAuthenticationState>,
    private _modalService: NgbModal,
    private router: Router,
    private projectStore: Store<IProjectState>,
    private localeService: BsLocaleService,
    private tzDatePipe: TzDatePipe,
    private awsService: AwsService,
    private fileUploadStore: Store<IFileUploadState>,
    private socketService: SocketService,

  ) {
    this.newDummyCampaign.id = -1;
    this.newDummyCampaign.name = 'All';
    this.campaigns = [this.newDummyCampaign];
    this.campaignId = -1;
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          this.user = user;
          this.isAdmin = this.user.role_user[0].role_id === 1;
        }
      });
    this.reportStore.dispatch(ResetReportState({params: {report: null, error: ''}}));
    this.subscribeStore();
    this.subscribeToSocket();

  }

  subscribeStore() {
    this.influencerStoreSubscribe();
    this.reportStoreSubscribe();
    this.campaignStoreSubscribe();
    this.fileStoreSubscribe();
  }

  setCurrentSelectedProject() {
    if (this.projectId && this.projects) {
      if(this.selectedFromList) {
        this.selectedFromList = null;
        this.referralIds = null;
        this.isTableHide = false;
      }
      this.isStartDateNull = false;
      this.isEndDateNull = false;
      this.selectedProject = this.projects.find(x => Number(x.id) === Number(this.projectId));
      this.dateEvents();
      if(this.selectedProject) {
        setTimeout(()=> {
          let doc =  this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
          doc.open();
          doc.write(this.selectedProject?.report_analysis || '');
          doc.close();
        },3000)
      } else {
        setTimeout(()=> {
          this.setCurrentSelectedProject();
        },300)
      }
    }
  }

  influencerStoreSubscribe() {
    this.influencerStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.projectStore.pipe(select(getProjects))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(projects => {
        if (projects) {
          this.projects = projects;
          this.setCurrentSelectedProject();
        }
      });
    this.influencerStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
          this.selectedInfluencerTypes['all'] = this.influencerTypes.map(x => this.selectedInfluencerTypes[x.id]).filter(Boolean).length === (this.influencerTypes || []).length;
          if (!this.selectedInfluencerTypes['all'] && this.campaignId === -1) {
            this.selectedInfluencerTypes['all'] = true;
            this.influencerTypes.forEach(item => {
              this.selectedInfluencerTypes[item.id] = true;
            })
          }
          this.influencerTypes.forEach(item => {
            this.influencerTypesById[item.id] = {};
            if (item.fields) {
              Object.keys(item.fields).forEach(key => {
                this.influencerTypesById[item.id][key] = true;
              });
            }
          });
        }
      });
    this.influencerStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.forceGroup = segments.length > 3;
          this.segments = segments;
          this.selectedChk['all'] = this.segments.map(x => this.selectedChk[x.id]).filter(Boolean).length === (this.segments || []).length;
          if (!this.selectedChk['all'] && this.campaignId === -1) {
            this.selectedChk['all'] = true;
            this.segments.forEach(item => {
              this.selectedChk[item.id] = true;
            })
          }
          this.doResize();
          // this.segments = [{
          //   id: null,
          //   name: 'All'
          // }, ...segments];
        }
      });
  }

  reportStoreSubscribe() {
    this.reportStore.pipe(select(getReportError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
          if (this.reportPasswordClick) {
            this.reportModel.close();
            this.reportPasswordClick = false;
            this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});

          }
        }
      });
    this.reportStore.pipe(select(getReport))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(report => {
        if (report) {
          this.report = report;
          if (this.refreshData) {
            this.reportTotals = null;
            this.pageViews = null;
            this.influencers = null;
          }
          this.refreshData = false;
          if (report.minMax) {
            this.justSetDate = true;
            if (this.isStartDateNull) {
              this.startDate = null;
            } else {
              this.startDate = this.startDate || report.minMax.firstImp;
              this.setStartDate = moment(this.startDate).toDate();
            }
            if (this.isEndDateNull) {
              this.endDate = null;
            } else {
              this.endDate = this.endDate || report.minMax.lastImp;
              this.setEndDate = moment(this.endDate).toDate();
            }
          }
          if (report.totals) {
            this.reportTotals = report.totals;
            this.generatePieChart();
            this.generateRadialPieChart();
          }
          if (report.pageViews) {
            this.pageViews = report.pageViews;
            this.getSeries();
          }
          if (report.conversions) {
            this.conversions = report.conversions;
            this.generateConversionsGraph();
          }
          if (report.influencers) {
            if (report.influencers.list.length > 0) {
              this.influencers = report.influencers;
              this.ajaxCallback({
                // recordsTotal: report.influencers.paging.total,
                // recordsFiltered: report.influencers.paging.total,
                recordsTotal: typeof report.influencers.paging.total === 'object' ? report.influencers.paging.total.length : report.influencers.paging.total,
                recordsFiltered: typeof report.influencers.paging.total === 'object' ? report.influencers.paging.total.length : report.influencers.paging.total,
                data: []
              });
              this.influencersList = report.influencers.list;
              if(!this.selectedFromList) {
                this.influencerFrontList = this.influencersList;
              }

              //
              // Recalculate datatable columns
              this.recalculateDatatableColumns();
            } else {
              this.influencers = null;
              this.influencersList = [];
              this.ajaxCallback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: []
              });

              //
              // Recalculate datatable columns
              this.recalculateDatatableColumns();
            }
          } else {
            this.ajaxCallback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: []
            });
          }
          if (report.referrals && report.referrals.length) {
            this.referrals = report.referrals;
            this.networkGraph = null;
            this.generateNetworkGraph(report.referrals);
          }
          if( !report.referrals && this.referrals && this.referrals.length) {
            this.generateNetworkGraph(this.referrals);
          }
          this.firstTime = false;
          setTimeout(() => {
            this.justSetDate = false;
            this.isReportLoading = false;
            this.loadModalReportRef.dismiss();
          })
        }
      });

    this.reportStore.pipe(select(getReportPassword))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(reportPassword => {
        if (reportPassword) {
          this.reportPasswordClick = false;
          if (this.reportModel) {
            this.reportModel.close();
          }
          this.temporaryToken = reportPassword;
          this.initData();
        }
      });
  }

  campaignStoreSubscribe() {
    this.campaignStore.pipe(select(getCampaigns))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaigns => {
        if (campaigns && campaigns.length > 0) {
          this.campaigns = [
            this.newDummyCampaign,
            ...campaigns
          ];
        }
      });
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        // if (data !== undefined && data !== null) {
        //   this.importProgress = {
        //     status: 'uploading',
        //     progress: data
        //   };
        //   if (data >= 100) {
        //     this.importProgress = {
        //       status: 'started',
        //       progress: 0
        //     };
        //   }
        // }
      });

    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.fileResponse && this.fileResponse.length > 0) {
          this.csvUrl = this.fileResponse[0]['url'].split("?")[0];
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(this.blobObject);
          a.download = this.fileName;
          a.click();
          // this.exportModelRef.close();
          this.csvDownloadComplete = true;
          this.exportProgress.status = 'started';
          this.exportProgress.progress = '0';
        }
      });
  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.data?.requesterType === 'project_report_advocates_export' && (!this.exportJobId || message?.data?.id === this.exportJobId) && message?.data?.uniqueImportString === this.uniqueImportString) {
          if (!this.csvDownloadComplete) {
            this.exportProgress.status = 'started';
            this.exportProgress.progress = '0';
          }
          // Set job id
          this.exportJobId = message.data.id;
          this.exportProgress.status = message.data?.result?.status;
          this.exportProgress.progress = message?.data?.result?.progress ? message?.data?.result?.progress : 0;
          // adjust loading state for finished/failed
          const status: string = this.exportProgress?.status;
          if (['finished', 'failed'].includes(this.exportProgress.status)) {
            setTimeout(async () => {
              this.loaderImport.next(false);
              // Keep status
              // Restart progress upon failed
              if (status === 'finished' && !this.exportProcess) {
                this.exportProcess = true;
                if (message?.data?.result?.fileUrl) {
                  this.csvUrl = message?.data?.result?.fileUrl;

                  // Download export file
                  await this.downloadExportFile();

                  this.csvDownloadComplete = true;
                  this.exportProgress.status = 'started';
                  this.exportProgress.progress = '0';
                }
                this.exportJobId = null;
              }
              if (status === 'failed') {
                this.exportModelRef.close();
                this.csvDownloadComplete = false;
                this.exportJobId = null;
              }
            }, 1500);
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  async downloadExportFile() {
    const fileName = this.csvUrl.split('/').pop();
    const response = await fetch(this.csvUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }

  getSignedUrlFromFile(file) {
    return new Promise((resolve, reject) => this.awsService.getAwsSignedUrl(file.name, file.type, true).subscribe((resp: any) => {
      resolve({
        url: resp.data.url,
        fileData: file
      });
    }, reject));
  }



  shareReport() {
    // this.loader$ = null;
    const modelRef = this._modalService.open(ShareReportModalComponent, {
      centered: false,
      size: 'lg',
      keyboard: true,
    });
    modelRef.componentInstance.projectId = this.projectId;
    modelRef.componentInstance.selectedProject = this.selectedProject;
  }

  trackingAccuracy() {
    const modelRef = this._modalService.open(TrackingAccuracyModalComponent, {
      centered: false,
      size: 'lg',
      keyboard: true,
    });
    modelRef.componentInstance.projectId = this.projectId;

    modelRef.result.then((result) => {
      if(result) {
        this.getImpressions();
      }
    })
  }

  showColumns(event) {
    this.showAllColumns = event.target.checked;
    this.recalculateDatatableColumns();
    this.doResize();
  }

  changeChk(type, event) {
    if(this.selectedFromList) {
      this.selectedFromList = null;
      this.referralIds = null;
      this.isTableHide = false;
    }
    this.selectedChk[type] = event.target.checked;
    if (type === 'all') {
      (this.segments || []).forEach(item => {
        this.selectedChk[item.id] = event.target.checked;
      });
    } else {
      const sync = {...this.selectedChk};
      delete sync['all'];
      this.selectedChk['all'] = Object.values(sync).filter(Boolean).length === (this.segments || []).length;
    }
    let ids = null;
    if (!this.selectedChk['all']) {
      ids = [];
      (this.segments || []).forEach(item => {
        if (this.selectedChk[item.id]) {
          ids.push(item.id);
        }
      });
      ids = ids.length ? ids : null;
    }
    if (this.chkTimeout) {
      clearTimeout(this.chkTimeout);
    }
    this.chkTimeout = setTimeout(() => {
      this.segmentSelected(ids);
      this.chkTimeout = null;
    }, 1000);
  }


  changeInfluencerType(type, event) {
    if(this.selectedFromList) {
      this.selectedFromList = null;
      this.referralIds = null;
      this.isTableHide = false;
    }
    this.selectedInfluencerTypes[type] = event.target.checked;
    if (type === 'all') {
      (this.influencerTypes || []).forEach(item => {
        this.selectedInfluencerTypes[item.id] = event.target.checked;
      });
      this.startDate = null;
      this.endDate = null;
    } else {
      const sync = {...this.selectedInfluencerTypes};
      delete sync['all'];
      this.selectedInfluencerTypes['all'] = Object.values(sync).filter(Boolean).length === (this.influencerTypes || []).length;
    }
    let ids = null;
    if (!this.selectedInfluencerTypes['all']) {
      ids = [];
      (this.influencerTypes || []).forEach(item => {
        if (this.selectedInfluencerTypes[item.id]) {
          ids.push(item.id);
        }
      });
      ids = ids.length ? ids : null;
    }
    if (this.chkTimeout) {
      clearTimeout(this.chkTimeout);
    }
    this.chkTimeout = setTimeout(() => {
      this.influencerTypesSelected(ids);
      this.chkTimeout = null;
    }, 1000);
  }

  exportData() {
    // this.influencerStore.dispatch(InfluencersExport());
  }

  showAllInfluencers() {
    this.referralIds = null;
    this.selectedSocialNetwork = null;
    if(this.selectedFromList.referredBy) {
      this.showFrontData(this.selectedFromList.referredBy);
      this.isTableHide = false;
    }
    else {
      this.isTableHide = false;
      this.selectedFromList = null;
    }
    this.getImpressions('influencers', undefined, undefined, '');
  }

  showFrontData (referralId) {
    this.referralIds = referralId;
    // this.selectedFromList = this.influencersList.filter(x => x.id === referralId);
    this.influencerFrontList.forEach(x => {
      if(x.id === referralId) {
        this.selectedFromList = x;
      }
    })
    this.selectedSocialNetwork = null;
    // this.getImpressions('influencers', undefined, undefined, '');
  }

  getEmailOpenRatePercentage(record) {
    if (record && record.emailsOpenRate) {
      if (record.emailsOpenRate.opened > 0 && record.emailsOpenRate.sent) {
        return ((record.emailsOpenRate.opened / record.emailsOpenRate.sent) * 100).toFixed(2);
      }
      return 0;
    } else {
      return 0;
    }
  }

  getEmailClickRatePercentage(record) {
    if (record && record.emailsOpenRate) {
      if (record.emailsOpenRate.clicked > 0 && record.emailsOpenRate.sent > 0) {
        return ((record.emailsOpenRate.clicked / record.emailsOpenRate.sent) * 100).toFixed(2);
      }
      return 0;
    } else {
      return 0;
    }
  }

  getCTARatePercentage(record) {
    if (record && record.registrationClicks) {
      if (record.registrationClicks > 0 && record.pageViews > 0) {
        return ((record.registrationClicks / record.pageViews) * 100).toFixed(2);
      }
      return 0;
    } else {
      return 0;
    }
  }

  getReferralsRatePercentage(record) {
    if (record && record.referrals.all) {
      if (record.referrals.all > 0 &&  record.registrationClicks > 0) {
        return ((record.referrals.all / record.registrationClicks) * 100).toFixed(2);
      }
      return 0;
    } else {
      return 0;
    }
  }

  ngOnInit() {
    // this.loadReport = document.getElementById('loadReport');
    this.loadModalReportRef = this._modalService.open(this.modalLoadReport, {
      centered: true,
      size: 'sm',
      keyboard: false,
      backdrop : 'static',
    });
    this.loadModalReportRef.result.then((result) => {
    }, (reason) => {
    });
    this.localeService.use('en-gb');
    this.activatedRoute.params.subscribe(params => {

      if (params.projectId) {
        this.projectId = params.projectId;
      }
    });
    this.datatableSettings();
    if (this.user) {
      this.initData();
    } else {
      this.activatedRoute.params.subscribe(p => {
        this.projectId = +p.projectId;
        this.setCurrentSelectedProject();
        this.showPasswordForm();

        this.selectedProject = this.projects.find(x => Number(x.id) === Number(this.projectId));
      });
    }
    this.initResize();
  }

  initData() {
    this.activatedRoute.queryParams.subscribe(async (params) => {
      const {campaignId, mode, influencerTypeId, segmentId, startDate, endDate} = params;
      let selectedCampaignId = null;
      let selectedInfluencerTypeId = null;
      let selectedSegmentId = null;
      let selectedStartDate = null;
      let selectedEndDate = null;

      let source = 'live';
      if (campaignId) {
        selectedCampaignId = Number(campaignId);
      }

      if (influencerTypeId) {
        selectedInfluencerTypeId = JSON.parse(influencerTypeId);
      }

      if (segmentId) {
        selectedSegmentId = JSON.parse(segmentId);
      }

      if (startDate) {
        selectedStartDate = startDate;
      }

      if (endDate) {
        selectedEndDate = endDate;
      }

      if (mode) {
        source = mode;
      }

      this.mode = source;
      this.campaignId = selectedCampaignId || -1;
      this.influencerTypeId = selectedInfluencerTypeId;
      this.selectedSegment = selectedSegmentId;
      this.startDate = selectedStartDate;
      this.endDate = selectedEndDate;

      if (this.selectedSegment && this.selectedSegment.length) {
        this.selectedSegment.forEach(item => {
          this.selectedChk[item] = true;
        })
      }

      if (this.influencerTypeId && this.influencerTypeId.length) {
        this.influencerTypeId.forEach(item => {
          this.selectedInfluencerTypes[item] = true;
        })
      }
    });
    this.activatedRoute.params.subscribe(p => {
      this.projectId = +p.projectId;
      this.startDate = null;
      this.endDate = null;
      this.setCurrentSelectedProject();
      if (this.dtElement && this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api<any>) => {
          dtInstance.destroy();
          this.firstTime = true;
          this.dtTrigger.next(undefined);
        });
      } else {
        this.firstTime = true;
        this.dtTrigger.next(undefined);
      }
      this.getCampaignsList();
      this.influencerStore.dispatch(InfluencersTypeList({params: {influencer_fields: true}}));
      this.influencerStore.dispatch(InfluencersProjectSegment({projectId: this.projectId}));
    });
  }

  getCampaignsList() {
    this.campaignStore.dispatch(CampaignGetAll({filter: {project_id: this.projectId, options: JSON.stringify({include_influencer_stats: true})}}));
  }

  campaignSelected(event) {
    this.refreshData = true;
    this.campaignId = event ? event.value : null;
    if (this.campaignId > 0) {
      if (this.selectedChk['all']) {
        this.selectedChk['all'] = false;
        this.segments.forEach(item => {
          this.selectedChk[item.id] = false;
        });
        this.selectedSegment = [];
        this.influencerTypeId = [];
      }
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        campaignId: this.campaignId && this.campaignId > 0 ? this.campaignId : null
      },
      queryParamsHandling: 'merge'
    });
    this.getImpressions(undefined, undefined, undefined, undefined);
  }

  segmentSelected(ids = null) {
    this.selectedSocialNetwork = null;
    this.selectedSegment = ids;
    this.influencerTypeId = null;
    // if (ids && ids.length) {
    //   this.influencerTypeId = this.segments.filter(item => ids.includes(item.id)).map(x => x.influencer_type_id);
    // }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        // influencerTypeId: (this.selectedSegment && this.selectedSegment.length) ? JSON.stringify(this.influencerTypeId) : null,
        segmentId: (this.selectedSegment && this.selectedSegment.length) ? JSON.stringify(this.selectedSegment) : null
      },
      queryParamsHandling: 'merge'
    });

    this.getImpressions(undefined, undefined, undefined, undefined);
  }

  influencerTypesSelected(ids = null) {
    this.selectedSocialNetwork = null;
    this.selectedSegment = null;
    this.influencerTypeId = ids;
    // if (ids && ids.length) {
    //   this.influencerTypeId = this.influencerTypeId.filter(item => ids.includes(item.id)).map(x => x.influencer_type_id);
    // }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        influencerTypeId: (this.influencerTypeId && this.influencerTypeId.length) ? JSON.stringify(this.influencerTypeId) : null,
      },
      queryParamsHandling: 'merge'
    });



    this.getImpressions(undefined, undefined, undefined, undefined);
  }

  /*segmentSelected(event) {
    this.selectedSegment = event ? event.value : null;
    this.influencerTypeId = null;
    if (event) {
      this.influencerTypeId = this.segments.find(item => item.id === event.value).influencer_type_id;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        influencerTypeId: this.influencerTypeId,
        segmentId: this.selectedSegment
      },
      queryParamsHandling: 'merge'
    });

    this.getImpressions(undefined, undefined, undefined, undefined);
  }*/

  modeSelected(event) {
    this.mode = event ? event.value : null;
    this.refreshData = true;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        mode: this.mode
      },
      queryParamsHandling: 'merge'
    });
    this.getImpressions(undefined, undefined, undefined, undefined);
  }

  showData(referralIds) {
    this.referralBy = referralIds.name;
    this.referralIds = referralIds.id;
    this.selectedSocialNetwork = null;
    this.getImpressions('influencers', undefined, undefined, '');
    this.socialShareString = referralIds?.socialShareString || '';
  }

  ngAfterViewInit() {
    if (this.user || this.temporaryToken) {
      this.dtTrigger.next(undefined);
    }
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('referral-influencer-id')) {
        this.showData(event.target.getAttribute('referral-influencer-id'));
      }
    });
  }

  datatableSettings() {
    this.tableColumns = [...Array(46)].map(_ => ({
      data: null,
      orderSequence: ['desc', 'asc']
    }));
    const columns = ['name', 'referred_count', 'pageViews', 'registrationClicks', 'socialShare', 'company', 'segment', 'emailOpenStatus', 'emailClickStatus', 'source', 'referredThroughTrackerSource', 'created_at', 'updated_at', 'typeName' ,'email',
      'booth_number', 'first_name', 'last_name', 'job_title', 'phone', 'registration_url', 'sessions', 'slug', 'video_url',
      'custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5', 'custom_6', 'custom_7', 'custom_8', 'custom_9', 'custom_10'];
    this.dtOptions = {
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      pagingType: 'full_numbers',
      responsive: false,
      processing: true,
      serverSide: true,
      ordering: true,
      columnDefs: [{orderable: false, targets: 'no-sort'}],
      pageLength: 10,
      paging: true,
      scrollX: true,
      searching: true,
      lengthChange: false,
      // language: {
      //   info: ''
      // },
      // dom: '<"row view-filter"<"col-sm-12"<"pull-left"l><"pull-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"pi>>>',
      dom: "<'row'<'col-lg-8 col-md-7 col-sm-7 col-12 ml-auto'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-3'i><'col-sm-12 col-md-9'p>>",
      ajax: (dataTablesParameters: any, callback) => {
        // console.log(dataTablesParameters);
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api<any>) => {
          this.datatableInstance = dtInstance;
          (this.datatableInstance as any).columns.adjust();
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          const sections = !this.firstTime ? 'influencers' : undefined;
          (this.datatableInstance as any).page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          $('div.dataTables_filter input').attr('placeholder','Search name, email, company, etc');
          $('div.dataTables_filter input').attr('onfocus',"this.placeholder = ''");
          $('div.dataTables_filter input').attr('onblur','this.placeholder = "Search name, email, company, etc"');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                (this.datatableInstance as any).search(search).draw();
              }
            }, 1000);
          });
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: columns[dataTablesParameters.order[0].column]
          };
          this.getImpressions(sections, pageNumber, pageLength, dataTablesParameters.search.value, orderBy);
        });
      },
      columns: this.tableColumns,
      order: [[1, 'desc']],
      scrollY: '46vh',
      scrollCollapse: true,
    };
  }

  getInfluencerName(influencer) {
    if (influencer.company) {
      return influencer.company;
    } else {
      return `${influencer.first_name} ${influencer.last_name}`;
    }
  }

  getSocialCount(id, platform) {
    return this.reportTotals?.InfluencerSocialNetworksCount[`${id}-${platform}`] || 0;
  }

  // formatData(data: any) {
  //   return (data || []).map(item => {
  //     const action = item.action_value.replace("Share to ", "").replace("Share via ", ""); // Extract platform
  //     const createdAt = this._moment(item.created_at);
  //     const formattedDate = createdAt.format('YYYY/MM/DD HH:mm'); // Format date and time
  //
  //     return `${action} at ${formattedDate}`;
  //   }).join(' | ');
  // }
  //
  // getSocialData(id) {
  //   let socialShareData = this.reportTotals?.socialSharesData.filter(item => item.influencer_id === id)
  //   this.socialShareString = this.formatData(socialShareData)
  // }

  getImpressions(sections = 'influencers,totals,referrals,pageViews', pageNumber = 0, pageLength = 10,
                 search = '', orderBy = {'column': 'referred_count', 'dir': 'desc'}, exportAdvocates = false, exportType = 'csv') {
    const params = {
      ...(this.campaignId && this.campaignId > 0 ? {campaignId: this.campaignId} : {}),
      ...(this.influencerTypeId && this.influencerTypeId.length ? {influencerTypeId: JSON.stringify(this.influencerTypeId)} : {}),
      ...(this.selectedSegment && this.selectedSegment.length ? {segmentId: JSON.stringify(this.selectedSegment)} : {}),
      ...(this.startDate ? {startDate: moment(this.startDate).format('YYYY-MM-DD')} : {}),
      ...(this.endDate ? {endDate: moment(this.endDate).format('YYYY-MM-DD')} : {}),
      ...(this.uniqueImportString && exportAdvocates ? {uniqueImportString: this.uniqueImportString} : {}),
      ...(exportAdvocates ? {v1: (new Date()).getTime()} : {}),
      influencers: JSON.stringify({
        page: pageNumber + 1,
        perPage: pageLength,
        ...(this.referralIds ? {referredById: this.referralIds} : {}),
        keywords: search,
        ...(this.selectedSocialNetwork ? { socialNetwork: this.selectedSocialNetwork} : {}),
        ...(this.selectedSegment ? {typeId: this.selectedSegment} : {}),
      }),
      pageViews: JSON.stringify({
        dateFrom: this.startDate,
        dateTo: this.endDate,
      }),
      options: JSON.stringify({
        ...(exportAdvocates ? {skipPagination: true} : {}),
        sections,
        ...(this.mode ? {mode: this.mode} : {}),
        orderBy
      }),
      ...(exportAdvocates ? {exportType} : {}),
    };
    if (exportAdvocates) {
      return this.reportStore.dispatch(ReportAdvocatesExport({projectId: this.projectId, params}));
    } else {
      this.reportStore.dispatch(Report({projectId: this.projectId, params}));
    }
  }

  exportAdvocatesData(exportType) {
    this.uniqueImportString = (new Date().getTime()).toString(36);

    this.exportProcess = false;
    if (!['finished', 'failed'].includes(this.exportProgress.status)) {
      this.csvDownloadComplete = false;
    }
    if (!this.csvDownloadComplete) {
      this.exportProgress.status = 'started';
      this.exportProgress.progress = '0';
    }

    this.getImpressions('influencers', null, null, null, null, true, exportType);

    this.exportModelRef = this._modalService.open(this.csvDownload, {
      centered: true,
      size: 'md',
      keyboard: true,
    });
    this.exportModelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  getDate(date: any) {
    if (!date) return;
    return moment(date).toDate();
  }

  generateConversionsGraph() {
    if (this.conversions && this.reportTotals) {
      let dates = [];
      const series = [];
      this.conversions.forEach(view => {
        const date = moment(view.dim2, 'YYYY-MM-DD');
        dates.push(date);
      });

      // @ts-ignore
      dates.sort((a: any, b: any) => moment(a).format('X') - moment(b).format('X'));
      dates = [...new Set(dates.map(item => moment(item).format('DD-MM-YYYY')))];

      this.reportTotals.bySegments.forEach((item, index) => {
        const dataByDate = {};
        this.conversions.filter(view => view.dim3 === item.name).forEach(x => {
          dataByDate[moment(x.dim2, 'YYYY-MM-DD').format('DD-MM-YYYY')] = x.dim1;
        });

        const data = [];
        dates.forEach(each => {
          data.push(dataByDate[each] || 0);
        });

        series.push({
          color: this.typeColor[index],
          name: `${item.name}(${data && data.length ? data.reduce((a, b) => a + b) : 0})`,
          // name: item.name,
          data
        });
      });
      this.conversionsGraph.dataLabels = {enabled: false};
      this.conversionsGraph.tooltip = {
        enabled: true,
        shared: true,
        y: {
          formatter: undefined,
          title: {
            formatter: (y) => {
              return y.replace(/[^a-z]/gi, '');
            },
          },
        }
      };
      this.conversionsGraph.series = series;
      this.conversionsGraph.yaxis = {
        type: '',
        show: true,
        showAlways: true,
        title: {
          text: 'Referrals'
        }
      };
      this.conversionsGraph.xaxis = {
        show: false,
        type: 'category',
        title: {text: 'Dates', style: {
            cssClass: 'report-barchart-title'
          }},
        label: {show: true},
        tickAmount: 15,
        categories: dates,
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#78909C',
          height: 6,
          offsetX: 0,
          offsetY: 0
        }
      };
    }
  }

  getSeries() {
    const series = [];
    let dates = [];
    if (this.reportTotals && this.pageViews) {
      this.pageViews.forEach(view => {
        const date = moment(view.dim2, 'YYYY-MM-DD');
        dates.push(date);
      });

      // @ts-ignore
      dates.sort((a: any, b: any) => moment(a).format('X') - moment(b).format('X'));
      dates = [...new Set(dates.map(item => moment(item).format('DD-MM-YYYY')))];

      this.reportTotals.bySegments.forEach((item, index) => {
        const dataByDate = {};
        this.pageViews.filter(view => view.dim3 === item.name).forEach(x => {
          dataByDate[moment(x.dim2, 'YYYY-MM-DD').format('DD-MM-YYYY')] = x.dim1;
        });

        const data = [];
        dates.forEach(each => {
          data.push(dataByDate[each] || 0);
        });

        series.push({
          color: this.typeColor[index],
          name: item.name,
          data
        });
      });
    }
    this.dates = dates;
    this.series = series;
  }

  zoomOut() {
    this.scaleFactor = 0.1;
    const zoomOutOptions = {
      scale: 1.0,
      offset: {x: 0, y: 0},
      animation: true
    };
    this.networkGraph.fit({animation: zoomOutOptions});
  }

  zoomIn() {
    if (this.scaleFactor < 1) {
      this.scaleFactor += 0.1;
      const zoomInOptions = {
        position: {x: 0, y: 0},
        scale: this.scaleFactor,
        offset: {x: 0, y: 0},
        animation: true
      };
      this.networkGraph.moveTo(zoomInOptions);
    }
  }

  getImageOrIconForGraph(image, typeName, size = 30) {
    if (image) {
      return {
        shape: typeName === 'campaigns' ? 'image' : 'circularImage',
        // shape: 'circularImage',
        image,
        type: typeName,
        brokenImage: 'assets/images/snowflake.png',
        size,
      };
    }
    return {
      shape: 'icon',
      type: typeName,
      icon: {
        face: 'FontAwesome',
        code: '\uf2dc',
        size,
        ...(typeName ? {color: this.groupTypeColor[typeName.toLowerCase()]} : {}),
      }
    };
  }

  createDataTree(dataset) {
    const singles = {};
    dataset.forEach(item => singles[item.id] = {...item, referredTo: []});
    const dataTree = [];
    dataset.forEach(item => {
      if (item.parentId) {
        singles[item.parentId].referredTo.push(singles[item.id]);
      } else {
        dataTree.push(singles[item.id]);
      }
    });
    return dataTree;
  }

  generateNetworkGraph(referrals) {
    if (referrals?.length) {
      document.getElementById('loadingBar').style.opacity = '1';
      document.getElementById('loadingBar').style.display = 'block';
    } else {
      document.getElementById('loadingBar').style.opacity = '0';
      document.getElementById('loadingBar').style.display = 'none';
    }
    const nodes = [];
    const edges = [];
    const parentWithChild = {};
    const referByMediaById = {};
    const mediaIcons = {
      mail: '\uf0e0',
      slack: '\uf198',
      whatsapp: '\uf232',
      linkedin: '\uf0e1',
      twitter: '\uf099',
      facebook: '\uf09a',
      google: '\uf1a0',
      instagram: '\uf16d'
    };

    const getMediaIcon = (type, size) => {
      const exist = Object.keys(mediaIcons).find(x => type.toLowerCase().includes(x));
      return {
        shape: 'icon',
        type: exist,
        icon: {
          face: 'FontAwesome',
          code: exist ? mediaIcons[exist] : '\uf2dc',
          size,
          color: this.socialNetworkColors[exist] || '#4ab8e3',
        }
      };
    };

    const headNodes = {};
    referrals.forEach(item => {
      if (item.referredByMedia) {
        referByMediaById[item.id] = item.referredByMedia;
      }
      headNodes[item.id] = (this.campaignId && this.campaignId > 0 ? this.campaignId : null) || item.campaign_id;
      if (!parentWithChild[item.id]) {
        parentWithChild[item.id] = {
          id: item.id,
          parentId: item.referredBy?.id || null,
        };
      } else if (parentWithChild[item.id] && !parentWithChild[item.id].parentId && item.referredBy) {
        parentWithChild[item.id].parentId = item.referredBy.id;
      }
      if (item.referredBy) {
        headNodes[item.referredBy.id] = (this.campaignId && this.campaignId > 0 ? this.campaignId : null) || item.campaign_id;
        if (item.referredBy.referredByMedia) {
          referByMediaById[item.referredBy.id] = item.referredBy.referredByMedia;
        }
        if (!parentWithChild[item.referredBy.id]) {
          parentWithChild[item.referredBy.id] = {
            id: item.referredBy.id,
            parentId: item.referredBy?.referredBy?.id || null,
          };
        }
      }
    });

    const depthCountById = {};

    const tree = this.createDataTree(Object.values(parentWithChild));


    const fillDepthCount = (dataArray, ids = []) => {
      dataArray.forEach(item => {
        if (!depthCountById[item.id]) {
          depthCountById[item.id] = (item.referredTo || []).length;
        }

        ids.forEach(id => {
          if (!depthCountById[id]) {
            depthCountById[id] = 0;
          }
          depthCountById[id] += (item.referredTo || []).length;
        });

        if ((item.referredTo || []).length > 0) {
          fillDepthCount(item.referredTo, [item.id, ...ids]);
        }
      });
    };

    fillDepthCount(tree);

    const minNodeSize = 45;
    const maxCount = Math.max(...(Object.values(depthCountById) as number[]));
    const mediaSize = 40;
    const campaignSize = minNodeSize + (maxCount * 5) + 10;
    const projectSize = minNodeSize + (maxCount * 5) + 15;
    const existMediaNode = {};

    const getEdgeMedia = (val) => {
      const exist = Object.keys(mediaIcons).find(x => val.toLowerCase().includes(x));
      return exist ? {
        label: mediaIcons[exist],
        ...(this.socialNetworkColors[exist] ? {font: {color: this.socialNetworkColors[exist], oldColor: this.socialNetworkColors[exist], size: mediaSize}} : {})
      } : {label: val};
    };

    // if (this.selectedProject && !this.campaignId) {
    //   nodes.push({
    //     id: `project-${this.selectedProject.id}`,
    //     ...this.getImageOrIconForGraph(this.selectedProject.image, null, projectSize),
    //     label: (this.selectedProject.title),
    //   });
    // }
    const pushedEdges = {};
    // this.campaigns.forEach(item => {
    //   if (this.campaignId) {
    //     if (item.id === this.campaignId) {
    //       nodes.push({
    //         id: `campaign-${item.id}`,
    //         ...this.getImageOrIconForGraph('assets/images/campaign-new.png', 'campaigns', campaignSize),
    //         label: (item.name || ''),
    //       });
    //       // if (this.selectedProject) {
    //       //   edges.push({from: `project-${this.selectedProject.id}`, to: `campaign-${item.id}`, arrows: 'to'});
    //       // }
    //     }
    //   } else {
    //     nodes.push({
    //       id: `campaign-${item.id}`,
    //       ...this.getImageOrIconForGraph('assets/images/campaign-new.png', 'campaigns', campaignSize),
    //       label: (item.name || ''),
    //     });
    //     if (this.selectedProject) {
    //       edges.push({from: `project-${this.selectedProject.id}`, to: `campaign-${item.id}`, arrows: 'to'});
    //     }
    //   }
    // });


    const isItemPushed = {};
    referrals.forEach(item => {
      // let size = ((depthCountById[item.id] || 0) * 100 / maxCount) + minNodeSize;
      let size = ((depthCountById[item.id] || 0) * 5) + minNodeSize;
      if (!isItemPushed[item.id]) {
        nodes.push({
          id: item.id,
          ...this.getImageOrIconForGraph(item.image, item.typeName, size),
          // label: item.id + ' - ' + (item.name || '') + ' - ' + (depthCountById[item.id] || 0),
          label: (item.name || ''),
        });
        isItemPushed[item.id] = true;
      }

      if (item.referredBy) {
        if (!isItemPushed[item.referredBy.id]) {
          // size = ((depthCountById[item.referredBy.id] || 0) * 100 / maxCount) + minNodeSize;
          size = ((depthCountById[item.referredBy.id] || 0) * 5) + minNodeSize;
          nodes.push({
            id: item.referredBy.id,
            ...this.getImageOrIconForGraph(item.referredBy.image, item.referredBy.typeName, size),
            // label: item.referredBy.id + ' - ' + (item.referredBy.name || '') + ' - ' + (depthCountById[item.referredBy.id] || 0)
            label: (item.referredBy.name || '')
          });
          isItemPushed[item.referredBy.id] = true;
        }
        if (referByMediaById[item.id]) {
          /*const mediaNodeId = `${referByMediaById[item.id]}-${item.referredBy.id}`;
          if (!existMediaNode[mediaNodeId]) {
            nodes.push({
              id: mediaNodeId,
              ...getMediaIcon( referByMediaById[item.id], mediaSize),
              label: '',
            });
            edges.push({from: item.referredBy.id, to: mediaNodeId, arrows: 'to'});
            existMediaNode[mediaNodeId] = true;
          }
          edges.push({from: mediaNodeId, to: item.id, arrows: 'to'});*/
          if (!pushedEdges[`${item.referredBy.id}-${item.id}`]) {
            // edges.push({from: item.referredBy.id, to: item.id, arrows: 'to', label: referByMediaById[item.id]});
            edges.push({from: item.referredBy.id, to: item.id, arrows: 'to', ...getEdgeMedia(referByMediaById[item.id])});
            pushedEdges[`${item.referredBy.id}-${item.id}`] = true;
          }
          delete headNodes[item.id];
        } else if (item.referredBy.id !== item.id) {
          delete headNodes[item.id];
          if (!pushedEdges[`${item.referredBy.id}-${item.id}`]) {
            edges.push({from: item.referredBy.id, to: item.id, arrows: 'to'});
            pushedEdges[`${item.referredBy.id}-${item.id}`] = true;
          }
        }
      }
    });
    // Object.keys(headNodes).forEach(item => {
    //   if (referByMediaById[item]) {
    //     // const mediaNodeId = `${referByMediaById[item]}-campaign-${headNodes[item]}`;
    //     //
    //     // if (!existMediaNode[mediaNodeId]) {
    //     //   nodes.push({
    //     //     id: mediaNodeId,
    //     //     ...getMediaIcon( referByMediaById[item], mediaSize),
    //     //     label: '',
    //     //   });
    //     //   edges.push({from: `campaign-${headNodes[item]}`, to: mediaNodeId, arrows: 'to'});
    //     //   existMediaNode[mediaNodeId] = true;
    //     // }
    //     // edges.push({from: mediaNodeId, to: item, arrows: 'to'});
    //     // edges.push({from: `campaign-${headNodes[item]}`, to: item, arrows: 'to', label: referByMediaById[item]});
    //     edges.push({from: `campaign-${headNodes[item]}`, to: item, arrows: 'to', ...getEdgeMedia(referByMediaById[item])});
    //   } else {
    //     edges.push({from: `campaign-${headNodes[item]}`, to: item, arrows: 'to'});
    //   }
    // });
    // edges = [{from: 2, to: 8, arrows: "to"}];
    const container = document.getElementById('mynetwork');
    const data = {
      nodes: new vis.DataSet(nodes),
      edges: new vis.DataSet(edges),
    };
    const options: any = {
      nodes: {
        font: {
          size: 15,
          color: '#000'
        },
        borderWidth: 2,
        shadow: false,
        shapeProperties: {
          interpolation: false
        }
      },
      edges: {
        length: 200,
        width: 1.5,
        font: {
          face: 'FontAwesome',
        },
        smooth: {
          // enabled: false,
          // type: 'continuous'
        },
        color: '#4ab8e3',
        // color: { inherit: 'from' },
      },
      physics: {
        forceAtlas2Based: {
          gravitationalConstant: -1000,
          centralGravity: 0.05,
          springLength: 300,
          springConstant: 0.18,
          avoidOverlap: 1
        },
        maxVelocity: 146,
        solver: 'forceAtlas2Based',
        timestep: 0.35,
        stabilization: {
          enabled: true,
          iterations: 1000,
          updateInterval: 25
        }
      },
      // physics: {
      //   adaptiveTimestep: true,
      //   barnesHut: {
      //     gravitationalConstant: -8000,
      //     springConstant: 0.04,
      //     // springLength: 300
      //   },
      //   // stabilization: {
      //   //   iterations: 987
      //   // }
      // },
      layout: {
        randomSeed: 191006,
        improvedLayout: false
      }
    };
    this.networkGraph = new vis.Network(container, data, options);
    this.networkGraph.on('stabilizationIterationsDone', () => {
      this.networkGraph.setOptions( { physics: false } );
    });
    // this.networkGraph.on('click', (params) => {
    //   const nodeId = params.nodes[0];
    //   if (nodeId) {
    //     const moveToOptions = {
    //       scale: 1.0,
    //       offset: {x: 0, y: 0},
    //       animation: {
    //         duration: 1000,
    //         easingFunction: 'easeInOutQuad'
    //       }
    //     };
    //     this.networkGraph.focus(nodeId, moveToOptions);
    //   }
    // });
    const getColorFromType = (type) => {
      return type ? (this.groupTypeColor[type.toLowerCase()] || this.socialNetworkColors[type.toLowerCase()] || '#4ab8e3') : '#4ab8e3';
    };
    const getParentEdge = (nodeId) => {
      return data.edges.get({
        fields: ['from', 'id'],
        filter: (item) => {
          return item.to.toString() === nodeId.toString();
        }
      });
    };
    const getParentNode = (nodeId) => {
      const result = getParentEdge(nodeId);

      const parentIds = result.map(function(item) {
        return item.from;
      });

      if (parentIds.length) {
        const parentNode = data.nodes.get(parentIds);
        return parentNode.length ? parentNode[0] : null;
      }
      return null;
    };
    const maxParentDepth = 30;
    this.networkGraph.on('stabilizationProgress', (params) => {
      const widthFactor = params.iterations / params.total;

      document.getElementById('bar').style.width = Math.round(widthFactor * 100) + '%';
      document.getElementById('text').innerText =
        Math.round(widthFactor * 100) + '%';
    });
    this.networkGraph.once('stabilizationIterationsDone', () => {
      document.getElementById('text').innerText = '100%';
      document.getElementById('bar').style.width = '100%';
      document.getElementById('loadingBar').style.opacity = '0';
      // really clean the dom element
      setTimeout( () => {
        document.getElementById('loadingBar').style.display = 'none';
      }, 500);
    });
    const allNodes = data.nodes.get({ returnType: 'Object' });
    const allEdges = data.edges.get({ returnType: 'Object' });
    let highlightActive = false;
    this.networkGraph.on('click', (params) => {
      const newOptions = {...options, physics: false};
      if (params.nodes.length > 0) {
        newOptions.edges.color = 'rgba(200,200,200,0.5)';

        highlightActive = true;
        let i;
        const selectedNode = params.nodes[0];

        // tslint:disable-next-line:forin
        for (const key in allEdges) {
          allEdges[key].color = 'rgba(200,200,200,0.5)';
          if (allEdges[key].label && allEdges[key].font) {
            allEdges[key].font.color = 'rgba(200,200,200,0.5)';
          }
        }

        // tslint:disable-next-line:forin
        for (const nodeId in allNodes) {
          allNodes[nodeId].color = 'rgba(200,200,200,0.5)';
          if (allNodes[nodeId].icon) {
            allNodes[nodeId].icon.color = 'rgba(200,200,200,0.5)';
          }
          if (allNodes[nodeId].hiddenLabel === undefined) {
            allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
            allNodes[nodeId].label = undefined;
          }
        }
        let connectedNodes: any = {};
        const connectedEdge = {};
        params.nodes.forEach(item => {
          let parentNode = getParentNode(item);
          let parentEdge = getParentEdge(item);
          let depth = 1;
          while (!!parentNode && depth <= maxParentDepth) {
            connectedNodes[parentNode.id] = parentNode;

            if (parentEdge.length) {
              connectedEdge[parentEdge[0].id] = parentEdge[0];
            }

            if (parentNode) {
              parentEdge = getParentEdge(parentNode.id);
            }

            parentNode = getParentNode(parentNode.id);

            depth++;
          }
        });
        connectedNodes = Object.keys(connectedNodes);

        // tslint:disable-next-line:forin
        for (const key in connectedEdge) {
          allEdges[key].color = '#4ab8e3';
          if (allEdges[key].label && allEdges[key].font) {
            allEdges[key].font.color = allEdges[key].font?.oldColor || '#333333';
          }
        }

        // all first degree nodes get their own color and their label back
        for (i = 0; i < connectedNodes.length; i++) {
          allNodes[connectedNodes[i]].color = getColorFromType(allNodes[connectedNodes[i]].type);
          if (allNodes[connectedNodes[i]].icon) {
            allNodes[connectedNodes[i]].icon.color = getColorFromType(allNodes[connectedNodes[i]].type);
          }
          if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
            allNodes[connectedNodes[i]].label =
              allNodes[connectedNodes[i]].hiddenLabel;
            allNodes[connectedNodes[i]].hiddenLabel = undefined;
          }
        }

        // the main node gets its own color and its label back.
        allNodes[selectedNode].color = getColorFromType(allNodes[selectedNode].type);
        if (allNodes[selectedNode].icon) {
          allNodes[selectedNode].icon.color = getColorFromType(allNodes[selectedNode].type);
        }
        if (allNodes[selectedNode].hiddenLabel !== undefined) {
          allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
          allNodes[selectedNode].hiddenLabel = undefined;
        }
      } else if (highlightActive === true) {
        newOptions.edges.color = '#4ab8e3';

        // tslint:disable-next-line:forin
        for (const nodeId in allNodes) {
          allNodes[nodeId].color = getColorFromType(allNodes[nodeId].type);
          if (allNodes[nodeId].icon) {
            allNodes[nodeId].icon.color = getColorFromType(allNodes[nodeId].type);
          }
          if (allNodes[nodeId].hiddenLabel !== undefined) {
            allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
            allNodes[nodeId].hiddenLabel = undefined;
          }
        }

        // tslint:disable-next-line:forin
        for (const key in allEdges) {
          allEdges[key].color = '#4ab8e3';
          if (allEdges[key].label && allEdges[key].font) {
            // const getMediaObj = getEdgeMedia(allEdges[key].label);
            allEdges[key].font.color = allEdges[key].font?.oldColor || '#333333';
          }
        }
        highlightActive = false;
      }

      // transform the object into an array
      const updateArray = [];
      const edgeArray = [];
      for (const nodeId in allNodes) {
        if (allNodes.hasOwnProperty(nodeId)) {
          if (allNodes[nodeId].color !== this.groupTypeColor.campaigns && allNodes[nodeId].type === 'campaigns') {
            allNodes[nodeId].image = 'assets/images/campaign-disabled-new.png';
          } else if (allNodes[nodeId].color === this.groupTypeColor.campaigns && allNodes[nodeId].type === 'campaigns') {
            allNodes[nodeId].image = 'assets/images/campaign-new.png';
          }
          updateArray.push(allNodes[nodeId]);
        }
      }

      for (const edgeId in allEdges) {
        if (allEdges.hasOwnProperty(edgeId)) {
          edgeArray.push(allEdges[edgeId]);
        }
      }
      this.networkGraph.setOptions(newOptions);
      data.nodes.update(updateArray);
      data.edges.update(edgeArray);
    });
  }

  getFormattedDate(date) {
    return new Date(moment(date, 'DD-MM-YYYY').format('MM/DD/YYYY'));
  }

  getLastSyncFormattedDate(date) {
    return moment(date).format('YYYY-MM-DD hh:mm a');
  }

  setDate(date: Date, type: string, forceClear = false) {
    if (!this.justSetDate) {
      const secondType = type === 'startDate' ? 'endDate' : 'startDate';
      if (date) {
        this.isStartDateNull = false;
        this.isEndDateNull = false;
        this[type] = moment(date).format('YYYY-MM-DD');
        if (!this[secondType]) {
          this[secondType] = moment(date).format('YYYY-MM-DD');
        }
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            ...(this[secondType] ? {[secondType]: this[secondType]} : {}),
            [type]: this[type]
          },
          queryParamsHandling: 'merge'
        });
        this.getImpressions(undefined, undefined, undefined, undefined);
      } else {
        if (forceClear) {
          if(type === 'startDate') this.isStartDateNull = true;
          if(type === 'endDate') this.isEndDateNull = true;

          this[type] = null;
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              [type]: this[type]
            },
            queryParamsHandling: 'merge'
          });
          this.getImpressions(undefined, undefined, undefined, undefined);
        }
      }
    }
  }

  showPasswordForm() {
    if (!this.temporaryToken) {
      const modelRef = this._modalService.open(this.passwordForm, {
        centered: true,
        size: 'md',
        keyboard: false,
        backdrop: 'static'
      });
      modelRef.result.then((result) => {
      }, (reason) => {
      });
    }
  }

  validatePassword(modal: any) {
    this.reportPasswordClick = true;
    this.reportModel = modal;
    if (this.reportPassword) {
      this.reportStore.dispatch(ReportPassword({projectId: this.projectId, password: this.reportPassword}));
    } else {
      this.reportPasswordError = 'Please enter password';
      return;
    }
  }

  generatePieChart() {
    if (this.reportTotals) {
      const socialNetworkCounts = this.reportTotals.socialNetworks;
      this.piechart.labels = Object.keys(socialNetworkCounts).map(item => {
        // return `${item.charAt(0).toUpperCase() + item.slice(1)} (${socialNetworkCounts[item]})`;
        return `${item.charAt(0).toUpperCase() + item.slice(1)}`;
      });
      this.piechart.series = Object.values(socialNetworkCounts);
      this.piechart.labels.map((label, index) => {
        const exist = Object.keys(this.socialNetworkColors).find(x => label.toLowerCase() === x);
        if (exist) {
          this.piechart.colors.push(this.socialNetworkColors[exist]);
        } else {
          this.piechart.colors.push(this.generateUniqueColor(index));
        }
      });
      this.piechart.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          let lbl = this.piechart.labels[config.dataPointIndex];
          lbl = (lbl ? lbl.replace(/[^a-z]/gi, '') : '').toLowerCase();
          this.selectedSocialNetwork = this.selectedSocialNetwork === lbl ? null : lbl;
          this.getImpressions('influencers', undefined, undefined, '');
        }
      }
    }
  }

  generateUniqueColor(index) {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 60%)`;
  }

  generateRadialPieChart() {
    if (this.reportTotals) {
      const totalTried = this.reportTotals.emailsOpenRate.total || 0;
      const totalSent = this.reportTotals.emailsOpenRate.sent || 0;
      // const bounce = (this.reportTotals.emailsOpenRate.opened || 0) - (this.reportTotals.emailsOpenRate.clicked || 0); // bounce = open - click
      const bounce = (this.reportTotals.emailsOpenRate.failed || 0);
      const totalData = {
        bounce: {
          label: 'Bounce',
          val: bounce || 0,
          per: totalTried ? Number((bounce || 0) * 100 / totalTried).toFixed(1) : 0,
        },
        optOut: {
          label: 'Opt-out',
          val: this.reportTotals.optedOut || 0,
          per: totalSent ? Number((this.reportTotals.optedOut || 0) * 100 / totalSent).toFixed(1) : 0,
        },
        clickRate: {
          label: 'Click Rate',
          val: this.reportTotals.emailsOpenRate.clicked || 0,
          per: totalSent ? Number((this.reportTotals.emailsOpenRate.clicked || 0) * 100 / totalSent).toFixed(1) : 0,
        },
        openRate: {
          label: 'Open Rate',
          val: this.reportTotals.emailsOpenRate.opened || 0,
          per: totalSent ? Number((this.reportTotals.emailsOpenRate.opened || 0) * 100 / totalSent).toFixed(1) : 0,
        },
      };
      // this.radialBar.series = [totalData.bounce.per, totalData.optOut.per, totalData.clickRate.per, totalData.openRate.per];
      this.radialBar.series = [totalData.openRate.per,  totalData.clickRate.per, totalData.optOut.per, totalData.bounce.per];
      // this.radialBar.stroke = {width: -2};
      this.radialBar.plotOptions = {
        radialBar: {
          hollow: {
            margin: 0,
            size: '40%',
          }
        }
      };
      this.radialBar.responsive = [
        {
          breakpoint: 1000,
          options: {
            legend: {
              position: 'top',
              itemMargin: {
                horizontal: 10,
                vertical: 5
              },
              markers: {
                offsetX: -5
              }
            },
            chart: {
              offsetY: 40
            }
          }
        },
        {
          breakpoint: 400,
          options: {
            chart: {
              offsetY: 65
            }
          }
        }
      ];
      this.radialBar.labels = ['Open Rate', 'Click Rate', 'Opt-out', 'Bounce'];
      this.radialBar.colors = ['#4AB8E3', '#6FCF97', '#F2994A', '#EB5757'];
      this.radialBar.legend = {
        show: true,
        inverseOrder: true,
        position: 'right',
        markers:{
          width: 7,
          height: 7,
          offsetX: -10
        },
        customLegendItems: [
          `<strong>Open Rate:</strong> ${totalData.openRate.per}% (${totalData.openRate.val})`,
          `<strong>Click Rate:</strong> ${totalData.clickRate.per}% (${totalData.clickRate.val})`,
          `<strong>Opt-out:</strong> ${totalData.optOut.per}% (${totalData.optOut.val})`,
          `<strong>Bounce:</strong> ${totalData.bounce.per}% (${totalData.bounce.val})`,
          `<strong>Send to:</strong> ${totalSent}`
        ],
        itemMargin: {
          horizontal: 3
        },
        horizontalAlign: 'center',
        // offsetY: 50,
        fontSize: '12px'
      };
    }
  }

  chartLabelFormatter(val, opts) {
    return [opts.w.config.labels[opts.seriesIndex], val.toFixed(1) + '%'];
  }

  formatInsertTime(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime.format('DD-MM-YYYY hh:mm a');
    }
    return '-';
  }

  formatMoment(insertTime) {
    const m = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (m.isValid()) {
      return m;
    }
    return null;
  }

  dateEvents() {
    this.currentProject = this.projects.find(x => Number(x.id) === Number(this.projectId));
    // this.projectStartDate = this.tzDatePipe.toLocalMomentObject(moment(this.currentProject?.start_date)).format(`YYYY-MM-DD${this.currentProject?.start_time ? ' hh:mm A' : ''}`);
    // this.projectEndDate = this.tzDatePipe.toLocalMomentObject(moment(this.currentProject?.end_date)).format(`YYYY-MM-DD${this.currentProject?.end_time ? ' hh:mm A' : ''}`);
    if (this.currentProject?.start_date)
    this.projectStartDate = this.tzDatePipe.toLocalMomentObject(this.currentProject?.start_date).format(`DD-MM-YYYY`);
    if (this.currentProject?.end_date)
    this.projectEndDate = this.tzDatePipe.toLocalMomentObject(this.currentProject?.end_date).format(`DD-MM-YYYY`);
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }

  initResize() {
    window.addEventListener('resize', (event) => {
      this.doResize();
    }, true);
  }

  doResize() {
    setTimeout(() => {
      const iflList = document.getElementById('influencer-list');
      if (iflList) {
        if (iflList.offsetWidth < 520 || this.segments?.length > 3) {
          this.forceGroup = true;
        } else {
          this.forceGroup = false;
        }
      }
    });
  }

  recalculateDatatableColumns() {
    setTimeout(() => {
      (this.datatableInstance as any).columns.adjust();
    }, 100);
  }

  hideTableState() {
    this.isTableHide = this.selectedFromList.referredCount === 0;
  }
}
