import {Component, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {
  getError,
  getResetPassword,
  getLoggedInUser,
  IAuthenticationState,
  LoginUser,
  ResetPassword,
  ResetAuthState,
  LogoutUser, ResetPasswordToken
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: UntypedFormGroup;
  returnUrl: string;
  submitted = false;
  token: string;
  unsubscriber = new Subject();
  confirmPasswordError: string;
  passwordStrength: any = '';
  showPasswords: any = {
    password: false,
    confirm_password: false,
  };
  timer = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private authenticationStore: Store<IAuthenticationState>
  ) {
    this.authenticationStore.dispatch(ResetAuthState({params: {error: ''}}));
    // localStorage.removeItem('resetUser');
    if (localStorage.getItem('resetUser') === null && !this.route.snapshot.queryParams['token']) {
      this.router.navigate(['/login']);
    }
    this.subscribeStores();
  }

  subscribeStores() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user && localStorage.getItem('resetUser') === null) {
            this.router.navigate(['/projects']);
          }
        }
      );

    this.authenticationStore.pipe(select(getResetPassword))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(resetPassword => {
        if (resetPassword) {
          if (resetPassword.success) {
            this.authenticationStore.dispatch(LoginUser({
              credentials: {
                email: resetPassword.user.email,
                password: this.f.password.value
              }
            }));
          }
        }
      });

    this.authenticationStore.pipe(select(getError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
  }

  ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    },
      {validators: [this.passwordMatchValidator, this.setNotMatchValidation.bind(this)]}
    );
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.token = this.route.snapshot.queryParams['token'];
    if (this.token) {
      this.tokenLogin();
    }
  }

  tokenLogin() {
    this.authenticationStore.dispatch(LogoutUser());
    this.authenticationStore.dispatch(ResetPasswordToken({token: this.token}));
  }

  setNotMatchValidation(frm: UntypedFormGroup) {
    if (frm.controls['password'].errors && !frm.controls['password'].errors.passwordRegex) {
      return;
    }
    if (this.passwordStrength >= 100) {
      frm.controls['password'].setErrors(null);
    } else {
      frm.controls['password'].setErrors({passwordRegex: true});
    }
  }

  updatePasswordStrength(event) {
    this.passwordStrength = event;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.resetPasswordForm.get('password').updateValueAndValidity();
    }, 200);
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    if (frm.controls['confirm_password'].errors && !frm.controls['confirm_password'].errors.confirmPasswordValidator) {
      return;
    }
    if (frm.controls['password'].value !== frm.controls['confirm_password'].value) {
      frm.controls['confirm_password'].setErrors({confirmPasswordValidator: true});
    } else {
      frm.controls['confirm_password'].setErrors(null);
    }
  }

  // Form Validation function.
  get f() {
    return this.resetPasswordForm.controls;
  }

  /**
   * Returns success object that will use for showing response messages .
   *
   * @param  url  an absolute URL giving the reset password page of the Application.
   * @param  name the location of the request, relative to the url.
   * @return      success object with user data.
   */

  onSubmit() {
    this.confirmPasswordError = '';
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    const currentuser = JSON.parse(localStorage.getItem('resetUser'));
    // stop here if form is invalid
    if (localStorage.getItem('resetUser') === null) {
      return;
    }
    this.authenticationStore.dispatch(ResetPassword({password: this.f.password.value, token: this.token}));
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }
}
