<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Conversion Tracking Accuracy</h4>
  <div class="d-flex justify-content-end">
    <button type="button" aria-label="Close button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>

<div class="w-auto">
  <div class="px-3 py-4">
    <div class="row accuracy-table">
      <div class="col-sm-12">
        <div class="custom-table mt-2">
          Accuracy numbers updated at {{_moment(trackingType[0]?.stat_updated_date).format('DD/MM/YYYY HH:mm')}}
          <table width="100%" class="sb-table table dataTable no-footer nowrap" datatable
                 [dtTrigger]="dtTrigger"
                 [dtOptions]="dtOptions" id="dt-table">
            <thead>
            <tr>
              <th><span>#</span></th>
              <th><span>Name</span></th>
              <th><span>Accuracy(%)</span></th>
              <th><span>Total</span></th>
            </tr>
            </thead>
            <tbody *ngIf="trackingType.length > 0">
            <ng-container *ngFor="let type of trackingType">
            <tr *ngIf="type.project_total > 0">
              <td>
                <input placeholder="" type="checkbox" [(ngModel)]="type.checked" id="type.id">
              </td>
              <td title="{{type.name}}">
                {{type.alias}}
              </td>
              <td title="Global Total: {{type.total}}">{{type.accuracy}}</td>
              <td>{{type?.project_total}}</td>
            </tr>
            </ng-container>
            </tbody>
            <tbody *ngIf="trackingType.length <= 0">
            <tr>
              <td colspan="7">
                No data found
              </td>
            </tr>
            </tbody>
          </table>
          <div>
            <button class="btn btn-default" (click)="saveTrackingAccurancy()" type="button">Save to include/exculde trackings remote report</button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4" style="display: none">
      <p class="font-weight-bold">
        Sample Report For Client For Using As a Template
      </p>
      <p>
        The Tier6(ip) number on this campaign is very high, even though it is not unusual for large campaigns but considering that this is our least accurate tracking system and the number is too close to our
        <br>
        Tier2 tracking system which is the most accurate one we're sure there are false numbers in there.
        <br>
        Tier7 in some cases can make mistake in detecting referrals and it's our last resort for capturing
        the conversions in case all the other were blocked by browsers. In our experience this usually happens when there are large number of organizational registrations since the companies' internal security policies can interfere with it (it appears to be the case for this campaign for example 291 from Eskam).
      </p>
      <p>
        In cases like this, after investigation if there are clear signs of abnormality with Tier6 or Tier7 tracking systems we offer excluding the conversions coming from that tracking systems from your report.
      </p>
      <p>
        * As you already know Google Analytics just like any other tracker can't be 100% accurate due to increasingly privacy protection measures.  so Snöball conversions are generally higher than GA since Snöball tracking system currently is not detect as such by browsers.
        <br>
        * Snöball currently does not count in batch registrations. it only counts these as one conversion regardless of how many people are registered at once. So our reported conversion number even if only Trier1-5 are used is almost always lower than the actual number since it's not possible to capture all conversions.
      </p>
    </div>
  </div>
</div>
