import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {
  getError,
  getRegisterUser,
  getLoggedInUser,
  IAuthenticationState,
  Register,
  getSuccess,
  ResetAuthState,
  ProjectGet, InfluencersProjectSegment, InfluencersGet
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Constants} from '@app/consts';

@Component({
  templateUrl: 'register.component.html',
  styleUrls: ['register.component.css'],
  providers: [Constants]
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup;
  visible = true;
  submitted = false;
  enquiryMessage: any = null;
  unsubscriber = new Subject();
  showPasswords: any = {
    password: false,
    confirm_password: false,
  };
  passwordStrength: any = '';
  timer = null;
  token = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private authenticationStore: Store<IAuthenticationState>,
    public constants: Constants,
    private route: ActivatedRoute,
  ) {
    this.authenticationStore.dispatch(ResetAuthState({params: {error: ''}}));
    this.subscribeStores();
    this.activatedRoute.params.subscribe(params => {
      if (params.token) {
        this.token = params.token;
      }
    });
  }

  subscribeStores() {
    // redirect to home if already logged in
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            if (this.route.snapshot.paramMap.get('token')) {
              this.token = this.route.snapshot.paramMap.get('token');
            } else {
              this.router.navigate(['/']);
            }
          }
        }
      );
    this.authenticationStore.pipe(select(getRegisterUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(registerUser => {
        if (registerUser) {
          this.enquiryMessage = {};
          this.enquiryMessage.info = registerUser;
          this.enquiryMessage.msg = this.token ? 'We have sent you a verification email.' : 'We will get back to you soon.';
          this.enquiryMessage.success = true;
          this.visible = !this.visible;
        }
      });

    this.authenticationStore.pipe(select(getError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.authenticationStore.pipe(select(getSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            // this.toastrService.success(success);
            this.toastrService.success(this.token ? 'We have sent you a verification email.' : success);
          }
        }
      });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      first_name: ['',  [Validators.required] ],
      last_name: ['', [Validators.required] ],
      job_title: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      website: ['', Validators.pattern(this.constants.urlRegex)],
      company: ['', Validators.required],
      phone: [''],
      comment: [''],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
    },
      {validators: [this.passwordMatchValidator, this.setNotMatchValidation.bind(this)]}
    );
  }


  setNotMatchValidation(frm: UntypedFormGroup) {
    if (frm.controls['password'].errors && !frm.controls['password'].errors.passwordRegex) {
      return;
    }
    if (this.passwordStrength >= 100) {
      frm.controls['password'].setErrors(null);
    } else {
      frm.controls['password'].setErrors({passwordRegex: true});
    }
  }

  updatePasswordStrength(event) {
    this.passwordStrength = event;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.registerForm.get('password').updateValueAndValidity();
    }, 200);
  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    if (frm.controls['confirm_password'].errors && !frm.controls['confirm_password'].errors.confirmPasswordValidator) {
      return;
    }
    if (frm.controls['password'].value !== frm.controls['confirm_password'].value) {
      frm.controls['confirm_password'].setErrors({confirmPasswordValidator: true});
    } else {
      frm.controls['confirm_password'].setErrors(null);
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  /**
   * Returns success object that will use for showing response messages .
   *
   * @param  url  an absolute URL giving the enquiry page of the Application.
   * @param  name the location of the request, relative to the url.
   * @return      success object with user data.
   */

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    const saveParams = {
      ...this.registerForm.value,
      ...(this.token ? {token: this.token} : {})
    };
    if (!saveParams) {
      delete saveParams.password;
      delete saveParams.confirm_password;
    }
    this.authenticationStore.dispatch(Register({user: saveParams}));
    // this.authenticationService.register(this.registerForm.value).subscribe(
    //   data => {
    //     this.enquiryMessage = data;
    //     if (this.enquiryMessage['Success']) {
    //       this.visible = !this.visible;
    //     }
    //     this.router.navigate(['/register']);
    //   },
    //   error => {
    //     this.alertService.error(error);
    //     this.loading = false;
    //   }
    // );
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }
}
