import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {ForgetPassword, getError, getForgetPassword, getLoggedInUser, IAuthenticationState, ResetAuthState} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  passwordForm: UntypedFormGroup;
  submitted = false;
  passMessage: any = {
    success: false,
    msg: ''
  };
  unsubscriber = new Subject();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private authenticationStore: Store<IAuthenticationState>
  ) {
    this.authenticationStore.dispatch(ResetAuthState({params: {error: '', forgetPassword: null}}));
    this.subscribeStores();
  }

  subscribeStores() {
    // redirect to home if already logged in
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        if (user) {
          this.router.navigate(['/']);
        }
      });

    this.authenticationStore.pipe(select(getForgetPassword))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(forgetPassword => {
        if (forgetPassword) {
          this.passMessage = forgetPassword;
          if (forgetPassword.success) {
            this.toastrService.success('Password reset link has been sent to your email.');
            this.router.navigate(['/login']);
          }
        }
      });

    this.authenticationStore.pipe(select(getError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() {
    return this.passwordForm.controls;
  }

  /**
   * Returns success message object that will use for Showing the response.
   *
   * @param  url  an absolute URL giving the reset Forgot password page of the Application.
   * @param  name the location of the request, relative to the url.
   * @return      success object with temporary login URL.
   */

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.passwordForm.invalid) {
      return;
    }
    this.authenticationStore.dispatch(ForgetPassword({email: this.passwordForm.value.email}));
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }
}
