<nav *ngIf="(currentUser) && (loaderService.showNavbar$ | async) === true" class="new-styles navbar navbar-expand-md fixed-top navbar-light bg-light">
  <div class="navbar-collapse collapse" id="navbarCollapse" >
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="routes.projects()">
          <div class="logo-container">
            <!-- <img alt="logo" src="../assets/images/logo_with_background_and_slogan_new.png"> -->
           <img *ngIf="(!inProjectContext)" alt="logo" src="../assets/images/logo_with_background_and_slogan_new.png">
                       <img *ngIf="(inProjectContext)" alt="logo" src="../assets/images/logo_with_background.png">
          </div>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav mr-auto menu-items">
      <li *ngIf="(inProjectContext)" class="nav-item dropdown projects-dropdown projects-links">
        <a class="nav-link dropdown-toggle d-flex align-items-center" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
           aria-expanded="false">
          <div class='project-logo mr-1'>
            <img [src]="imageDefault" *ngIf="!(project && project.image)" alt="{{project && project.title}}" title="{{project && project.title}}" />
            <span *ngIf="(project && project.image)" [style.background-image]="'url(' + ((project && project.image) || imageDefault) + ')'"></span>
          </div>
          {{project && project.title}}
        </a>
        <div class="dropdown-menu projects-list-dropdown">
          <div class="project-search">
            <input type="search" [(ngModel)]="projectSearchValue" class="form-control" placeholder="Search" (keyup)="onSearchProjectList($event)" (search)="onSearchProjectList()">
          </div>
          <div class="projects-list-option" >
            <a *ngFor="let project of this.projects" class="dropdown-item" (click)="projectRoute(project.id)">
              <div class='image-wrapper'>
                <img [src]="project.image || imageDefault" alt="{{project.title}}" title="{{project.title}}" />
                {{project && project.title}}
              </div>
            </a>
          </div>
        </div>
        <span *ngIf="project && inProjectContext && !isInfluencer" class="edit-project">
          <button (click)="redirectProject()" class="btn btn-icon">
<!--            <span class="fa fa-pencil"></span>-->
            <span class="fa fa-pencil-square-o" aria-hidden="true"></span>
          </button>
        </span>
      </li>
      <li *ngIf="(!inProjectContext && !isInfluencer)" class="nav-item">
        <a class="nav-link" [routerLink]="routes.projects()" routerLinkActive="active-link">PROJECTS</a>
      </li>
      <li *ngIf="(this.isAdmin() && !inProjectContext)" class="nav-item">
        <a class="nav-link" [routerLink]="routes.clients()" routerLinkActive="active-link">CLIENTS</a>
      </li>
      <li *ngIf="(inProjectContext && !isInfluencer)" class="nav-item">
        <a class="nav-link" [routerLink]="routes.campaigns({projectId: projectId})" routerLinkActive="active-link">CAMPAIGNS</a>
      </li>
      <li *ngIf="(inProjectContext && !isInfluencer)" class="nav-item">
        <a class="nav-link" [routerLink]="routes.influencers({projectId: projectId})" routerLinkActive="active-link">INFLUENCERS</a>
      </li>
      <li *ngIf="(inProjectContext && !isInfluencer)" class="nav-item">
        <a class="nav-link" [routerLink]="routes.statistics({projectId: projectId})" routerLinkActive="active-link">RESULTS</a>
      </li>
<!--      <li *ngIf="(this.isAdmin() && inProjectContext)" class="nav-item dropdown">-->
      <li *ngIf="(inProjectContext)" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          INTEGRATION
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="routes.widget({projectId: projectId})">Widget</a>
          <a class="dropdown-item" [routerLink]="routes.thirdpartyPlatforms({projectId: projectId})">Registration Platforms</a>
          <a class="dropdown-item" [routerLink]="['/widget-monitoring']">Monitor</a>
        </div>
      </li>
      <li *ngIf="(this.isAdmin() && !inProjectContext) || this.isManager()" class="nav-item">
        <a class="nav-link" [routerLink]="routes.users()" routerLinkActive="active-link">USERS</a>
      </li>
      <li *ngIf="(this.isAdmin())" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          SETTINGS
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="routes.settings()">Settings</a>
          <a class="dropdown-item" [routerLink]="[routes.settings(), 'registration-platform']">Registration Platforms</a>
          <a class="dropdown-item" [routerLink]="[routes.settings(), 'packages']">Packages</a>
          <a class="dropdown-item" [routerLink]="[routes.settings(), 'cache']">Cache</a>
          <a class="dropdown-item" [routerLink]="[routes.settings(), 'uploadFile']">Upload File</a>
          <a class="dropdown-item" [routerLink]="[routes.settings(), 'projects-overview']">Projects Overview</a>
          <a class="dropdown-item" [routerLink]="[routes.settings(), 'campaigns-overview']">Campaigns Overview</a>
          <a class="dropdown-item dropdown-item-export" (click)="exportProjectsReport()">Export Projects Report</a>
        </div>
      </li>
      <li *ngIf="(this.isAdmin())" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          TEMPLATES
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/templates']">Template</a>
          <a class="dropdown-item" [routerLink]="['/sections']">Sections</a>
        </div>
      </li>
    </ul>
  </div>
  <div class="btn-group pull-right login-part" dropdown>
    <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle btn-icon d-flex align-items-center"
            aria-controls="dropdown-basic">
      <span><img [alt]="imageSrc" [src]="imageSrc" style="height: 30px; border-radius: 50%;"></span>
      <span class="ml-1">Hello {{getNameOrEmail()}}</span>
      <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu  dropdown-menu-right" role="menu"
        aria-labelledby="button-basic">
      <li role="menuitem"><a class="dropdown-item" [routerLink]="['/users', 'profile', currentUser.id]">Profile</a></li>
      <li role="menuitem"><a class="dropdown-item" [routerLink]="['/users' , 'integration']">Integration</a></li>
      <li role="menuitem"><a class="dropdown-item" (click)="logout()">Log out</a></li>
    </ul>
  </div>
  <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

</nav>

<router-outlet></router-outlet>

<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="footer-menu">
          <div>
            <ul>
              <li>
                <a href="https://snoball.events" target="_blank" class="copyright">
                  &copy; Snöball
                </a>
              </li>
              <li>
                <a href="https://snoball.events/book-demo3/" target="_blank">
                  Get in Touch
                </a>
              </li>
              <li>
                <a href="https://i.snoball.it/b/privacy" target="_blank">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div class='build-info'>
            <h6 class='build'>commit: {{currentVersionInfo?.build_number}} date: {{currentVersionInfo?.build_date}} </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #csvDownload let-modal >
  <div class="modal-header">
    <h4
      class="modal-title"
      id="modal-title"
    >
      Export {{ !csvDownloadComplete ? ': Your data is being exported' : ''}}
    </h4>
    <div class="d-flex justify-content-end">
      <button
        type="button"
        aria-label="Close button"
        class="close"
        aria-describedby="modal-title"
        (click)="modal.close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <button class="btn btn-default" type="button" *ngIf='!csvDownloadComplete'>
        <span class="btn-wrapper--icon spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="btn-wrapper--label">
      {{exportProgress.status === 'started' ?
          'Export: started' :
          exportProgress.status === 'in-progress' ?
            'Exporting: '  + ' ' + exportProgress.progress + '%' :
            'Export: ' + exportProgress.status}}
    </span>
      </button>
      <a class="btn btn-default" type="button" *ngIf='csvDownloadComplete' (click)="downloadExportFile()">
        <span class="btn-wrapper--label">
          {{'File Link'}}
    </span>
      </a>

    </div>
  </div>
</ng-template>
